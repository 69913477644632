import request from '../utils/request'

export const search = (data,pageNum,pageSize) => request.get('/home/search?keyword=' + data + '&pageNum=' + pageNum + '&pageSize=' + pageSize);

export const login = (data) => request.post('/user/login',data);

export const register = (data) => request.post('/user/register',data);

export const getType = (data) => request.get('/home/type',data);

export const getHome = (data) => request.get('/home/index',data);

export const getAbout = (data) => request.get('/home/setting',data);

// export const search = (name) => request.get('/home/search?name=' + name);