<template>
  <div class="page-background">
    <v-header />
    <div class="user">
      <div class="left">
        <h3>Individual center</h3>
        <!-- <p :class="typeIndex == 1 ? 'xz' : ''" @click="jump('/user/info',1)">Personal data</p> -->
        <p :class="typeIndex == 2 ? 'xz' : ''" @click="jump('/user/order',2)">My order</p>
        <!-- <p :class="typeIndex == 3 ? 'xz' : ''" @click="jump('/user/collect',3)">我的收藏</p>
        <p :class="typeIndex == 4 ? 'xz' : ''" @click="jump('/user/course',4)">我的课程</p>
        <p :class="typeIndex == 5 ? 'xz' : ''" @click="jump('/user/code',5)">会员兑换码</p> -->
        <p :class="typeIndex == 6 ? 'xz' : ''" @click="jump('/user/account',6)">Change password</p>
        <!-- <p :class="typeIndex == 7 ? 'xz' : ''" @click="jump('/user/corporation',7)">公司信息</p>
        <p :class="typeIndex == 8 ? 'xz' : ''">档案管理</p>
        <p :class="typeIndex == 9 ? 'xz' : ''">付费指导</p>
        <p :class="typeIndex == 9 ? 'xz' : ''">个性化推荐</p> -->
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      typeIndex: 2
    };
  },
  methods: {
    jump(url,index) {
      this.typeIndex = index
      this.$router.push(url);
    },
  },
};
</script>
    
  
<style scoped>
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
@import url("./css/index.less");
</style>
  