<template>
    <div class="page-background" style="background: #fff">
        <v-header></v-header>
        <div class="breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/">All Products</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="typeName">{{typeName}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="data">
            <div class="left">
                <div class="types">
                    <div class="list">
                        <h2>Types</h2>
                        <div v-for="list in typeList" :key="list" @click="selectData(list)">
                            <h3>{{list.name}}</h3>
                            <p v-for="(v,key) in list.children" :key="key" @click.stop="selectDataTwo(v)">{{v.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="item">
                    <div class="list" v-for="(list, index) in productList" :key="index" @click="jump(list)">
                        <!--  <div class="imgs">
              <img :src="list.imgList[0]" alt="" />
            </div> -->
                        <div class="imgs">
                            <!-- <img :src="list.imgList[0]" alt="" /> -->
                            <el-carousel height="200px" :autoplay="false" :initial-index="list.index">
                                <el-carousel-item v-for="(v,key) in list.imgList" :key="key">
                                    <img :src="v" alt="" />
                                </el-carousel-item>
                                <!-- <el-carousel-item>
                          <img :src="list.imgList[1]" alt="" />
                      </el-carousel-item> -->
                            </el-carousel>
                        </div>
                        <div class="box">
                            <p class="type">{{ list.name }}</p>
                            <p class="name">{{ list.con }}</p>
                            <p class="price" v-if="list.info">
                                ${{ list.info.price ? list.info.price : 0 }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <el-pagination background @current-change="pageChange" :page-size="pageSize" layout="prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { type, list } from "@/api/product";
export default {
    data() {
        return {
            typeList: [],
            productList: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            pageNum: 1,
            pageSize: 20,
            total: 0,
            fid: '',
            type: '',
            typeName: ''
        };
    },
    created() {
        this.getList();
        this.getProduct();
        this.typeName = this.$route.query.typeName
    },
    methods: {
        jump(list) {
            this.$router.push("/productDetails?id=" + list.id);
        },
        pageChange(e) {
            this.pageNum = e;
            this.getProduct();
        },
        selectData(list) {
            this.typeName = list.name
            window.location.href = '#/product?type=' + list.name + '&typeName=' + list.name;
            window.location.reload()

            this.getProduct()
            // this.fid = []
            // if (list.children) {
            //     list.children.forEach(v => {
            //         this.fid.push(v.id)
            //     })
            // }
            // this.fid.push(list.id)
            // this.getProduct()
        },
        selectDataTwo(data) {
            this.type = ''
            // this.fid = data.id
            this.typeName = data.name

            window.location.href = '#/product?fid=' + data.id + '&typeName=' + data.name;
            window.location.reload()

            this.getProduct()
            // this.fid = []
            // this.fid.push(data.id)
            // this.getProduct()
            // console.log(this.fid)
        },
        async getList() {
            const res = await type(this.pageNum, this.pageSize);
            this.typeList = res.data;
        },
        async getProduct() {
            const res = await list(this.pageNum, this.pageSize, {
                // fid: this.fid.join(','),
                fid: this.$route.query.fid ? this.$route.query.fid : '',
                type: this.$route.query.type ? this.$route.query.type : ''
            });
            this.productList = res.data.list;
            this.total = res.data.total
        },
    },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1e71ce !important;
    border-color: #1e71ce !important;
}

/deep/ .el-tree-node__label {
    font-size: 17px !important;
}

/deep/ .el-checkbox__inner {
    width: 19px;
    height: 19px;
}

/deep/ .el-tree-node__content>.el-tree-node__expand-icon {
    display: none;
}

/deep/ .el-tree-node__content {
    margin-bottom: 5px;
    color: #000;
    font-weight: 500;
}

/deep/ .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

/deep/ .is-active .el-carousel__button {
    background: #fc4c02;
}

/deep/ .el-carousel__indicators--horizontal {
    bottom: -7px;
}
</style>