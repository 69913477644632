<template>
  <div id="app">
    <router-view v-if="routerAlive"></router-view>
  </div>
</template>

<script>
import { getAbout } from "@/api/home";
import { list as getList } from "@/api/url"
export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      routerAlive: true
    };
  },
  methods: {
    reload() {
      this.routerAlive = false;
      this.$nextTick(function() {
        this.routerAlive = true;
      });
    }
  },
  created() {
    getAbout().then(res => {
      this.$store.commit('ABOUT', JSON.stringify(res.data))
      // localStorage.setItem('about', JSON.stringify(res.data))
    }),
    getList().then(res => {
      this.$store.commit('URLLIST', JSON.stringify(res.data))
      // localStorage.setItem('about', JSON.stringify(res.data))
    })
    // getStatus('pdpvsadi',{
    //   TaskType: 'GetAllGroupContact',
    //   DBSync: true
    // }).then(res1 => {
    //   console.log(res1)
    // })
    // getControl(JSON.parse(JSON.stringify({ sandbox: "md5" }))).then(res => {
    //   console.log(res)
    //   getStatus('md5').then(res1 => {
    //     console.log(res1)
    //   })
    // });
  }
};
</script>
<style lang="less">
</style>