<template>
  <div>
    <div class="top">
      <div class="box">
        <div class="left">
          <div class="imgs">
            <img
              src="https://bidradar.oss-cn-shenzhen.aliyuncs.com/head_image/icon_35.png"
              alt=""
            />
          </div>
          <p class="phone">{{ info ? info.email : "" }}</p>
          <!-- <el-tag size="small" effect="dark">{{info.is_member == 0 ? '普通用户' : 'VIP用户'}}</el-tag> -->
        </div>
        <!-- <div class="right">
          <div v-if="info.is_member == 0">
            <p class="text">您还暂未开通会员，开通会员立享特权</p>
            <p class="btn">立即开通会员</p>
          </div>
          <div v-else>
            <p class="expiretime">会员到期时间：{{info.expiretime}}</p>
          </div>
        </div> -->
      </div>
    </div>

    <div class="bottom">
      <div class="table">
        <div class="ttop">
          <p>My order</p>
          <span>See more</span>
        </div>
        <div class="tbottom">
          <el-table
            :data="table"
            max-height="300"
            header-cell-style="background: #f5f7fa;"
          >
            <el-table-column align="center" prop="date" label="ID">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="name"
              label="product name"
              width="220"
              :show-overflow-tooltip="true"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="price"
              label="payment amount"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="price"
              label="state of payment"
            >
            <template slot-scope="scope">
              <p>{{scope.row.state == 0 ? '待付款' : '已完成'}}</p>
            </template>
            </el-table-column>
            <el-table-column
              width="200"
              align="center"
              prop="createtime"
              label="order time"
              :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="createtime"
              label="operation"
            >
            <template slot-scope="scope">
              <el-link v-if="scope.row.state == 1" @click.stop="delOrder(scope.row)" type="danger">删除订单</el-link>
              <el-link v-if="scope.row.state == 0" @click.stop="pay(scope.row)" type="primary">立即支付</el-link>
            </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import { getUser } from "@/api/user";
export default {
  data() {
    return {
      tableList: [],
      info: {},
      pageNum: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    if(process.browser && localStorage.getItem('user')) {
      this.info = JSON.parse(localStorage.getItem('user'))
    } else {
      this.getUserInfo();
    }
  },
  methods: {
    async getUserInfo() {
      const res = await getUser();
      this.info = res.data;
      this.$store.commit("GETUSERDATA", JSON.stringify(res.data));
    },
  },
};
</script>
      
    
<style scoped>
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
@import url("./css/info.less");
</style>
          
    