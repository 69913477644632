<template>
    <div>
        <el-dialog title="" :visible.sync="dialogVisible" :before-close="outClose" width="800px">
            <!-- <h3
          style="color: #000; font-size: 23px; text-align: center; height: 54px"
        >
          WenZhou Grand Dream Awards E-commerce Co.,Ltd.
        </h3> -->
            <div class="" ref="printMe" id="printMe">
                <!-- <p style="color:blue">客户：{{ data.name }}</p>
              <p style="color:blue">可用金额：{{ withdraw }}</p> -->
                <div class="top" style="
            display: flex;
            justify-content: space-around;
            flex-direction: column;
            margin-bottom: 10px;
            height:auto;
          ">
                    <h3 style="font-size: 19px; margin-bottom: 7px; color: #000">
                        WenZhou Grand Dream Awards E-commerce Co.,Ltd.
                    </h3>
                    <!-- <div class="" style="width: 50%">
            <p style="font-weight: 600; font-size: 14px; color: #000">
              Client：{{ data[0].company_name }}
            </p>
            <p style="font-weight: 600; font-size: 14px; color: #000">
              Take Address：{{ data[0].time }}
            </p>
          </div> -->
                    <div class="" style="width: 50%">
                        <p style="
                font-weight: 600;
                font-size: 14px;
                color: #000;
                text-align: right;
              "></p>
                        <p style="
                font-weight: 600;
                font-size: 14px;
                color: #000;
                text-align: right;
              "></p>
                    </div>
                </div>
                <table border="1" cellpadding="15px" cellspacing="0" style="width: 100%; margin: auto; text-align: center">
                    <tbody>
                        <tr>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Product Name
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Color
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Img
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Size
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Quantity
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Transaction amount
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                Time
                            </td>
                        </tr>
                        <tr v-for="(list, index) in dataList" :key="index">
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ list.name }}
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ list.color }}
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                <img :src="list.img" width="30px" height="30px" style="padding-top: 10px" />
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ list.size }}
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ list.number }}
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ list.price }}
                            </td>
                            <td style="
                  font-size: 13px;
                  height: 25px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ list.createtime }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="1" style="font-size: 11px; height: 30px; font-weight: 1000">
                                Total
                            </td>
                            <!-- <td style="font-size: 11px; font-weight: 800">
                {{ data.weight }}
              </td>
              <td style="font-size: 11px;height:25px; font-weight: 800; color: #000000"></td>
              <td style="font-size: 11px;height:25px; font-weight: 800; color: #000000"></td> -->
                            <td colspan="6" style="
                  font-size: 11px;
                  height: 30px;
                  font-weight: 800;
                  color: #000000;
                ">
                                {{ totalPrice }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <p style="
          margin: 0 0 20px;
          font-weight: 600;
          line-height: 26px;
          font-weight: 800;
          color: #000000;
        ">
                <!-- 温馨提示：<br />
          1：购销双方本着公平交易，互利互惠原则达成购销协议 <br />
          2：客户承诺以上出售所有物品是合法所有，不是赃物或违法所得，本人愿意承担经济和法律责任
          <br /> -->
                <!-- 3：来料提料请提前一天通知 <br /><br /> -->
            </p>
            <el-button @click="print">Immediate Printing</el-button>
            <!-- <p
          style="
            font-weight: 600;
            font-size: 14px;
            color: #000;
            margin-top: 15px;
            position: relative;
            top: -13px;
          "
        >
          &nbsp;&nbsp;制单：&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          复核：
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;客户签名：
        </p> -->
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        dataList: {
            type: Array,
            default: () => [],
        },
        dialogVisible: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            total: 0,
        };
    },

    methods: {
        print() {
            const printMe = this.$refs.printMe.innerHTML;
            document.body.innerHTML = `${printMe}`;
            window.print();
            location.reload();
        },

        outClose() {
            this.$emit("outClose");
        },
    },
    computed: {
        totalPrice() {
            var sum = 0;
            this.dataList.forEach((list) => {
                sum += parseInt(list.price);
            });
            return sum.toFixed(2);
        },
    },
};
</script>
<style lang="less" scoped>
.from {
    height: 500px;
}

/deep/ .el-upload-list {
    display: none;
}

/deep/ .el-dialog__body {
    padding: 10px 20px;
}

/* 弹出框居中显示 */
/deep/ .el-dialog {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
}

/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
    overflow: hidden;
    overflow-y: auto;
}

.list-dialog {
    //   margin-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .list {
        width: 100%;

        .el-input {
            width: 100%;
        }
    }
}

.search {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .left {
        width: 100%;
        display: flex;
        align-items: flex-end;

        .box {
            display: flex;

            .el-input {
                width: 300px;
                margin-right: 20px;
                margin-left: 20px;
            }
        }
    }

    .right {
        width: 40%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .box {
            display: flex;
        }
    }
}

.table {
    margin-top: 30px;

    .t-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 30px;

        .t-list {
            width: 19%;
            background: #fff;
            box-shadow: 0px 6px 58px 0px rgba(196, 203, 214, 0.3);
            padding-top: 20px;
            border-radius: 5px;
            margin-bottom: 1%;
            position: relative;

            p {
                text-align: center;
                margin-bottom: 0px !important;
            }

            .title {
                font-weight: 800;
                font-size: 15px;
                opacity: 0.5;
            }

            .img {
                width: 100%;
                height: auto;
                border-radius: 50%;
                object-fit: cover;
                display: block;
                margin: 20px auto;

                img {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    display: block;
                    margin: auto;
                }
            }

            .number {
                color: #000;
                font-weight: 800;
                font-size: 14px;
                opacity: 0.8;
            }

            .value {
                color: #000;
                opacity: 0.3;
                font-size: 12px;
                margin-top: 5px;
            }

            .datas {
                width: 100%;
                height: 50px;
                display: flex;
                justify-content: center;
                position: relative;
                top: 13px;
                padding-bottom: 20px;

                .box {
                    position: absolute;
                    left: 38%;
                    transform: translateX(-30%);

                    img {
                        width: 30px;
                        height: 30px;
                        border: 1px solid #ccc;
                        border-radius: 50%;
                        position: absolute;
                    }
                }
            }

            .time {
                font-weight: 800;
                font-size: 13px;
                padding-bottom: 20px;
                opacity: 0.5;
                margin-top: 5px;
            }

            .edit {
                position: absolute;
                right: 18px;
                top: 15px;
                color: #000;
                opacity: 0.4;
                font-weight: 800;
                cursor: pointer;
            }
        }
    }
}

.list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 13px;

    p {
        font-size: 15px;
        color: rgba(125, 133, 146, 1);
        margin-bottom: 10px;
    }

    .el-input {
        width: 100%;
    }
}

/deep/ .ql-snow .ql-editor img {
    max-width: 30% !important;
}

.itemIms {
    width: auto;
    display: flex;

    .img_list {
        width: 100%;
        height: auto;
        margin-top: 10px;
        position: relative;
        display: flex;
        justify-content: space-between;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        i {
            cursor: pointer;
        }

        .el-icon-document {
            font-weight: 700;
        }

        p {
            // position: absolute;
            // width: 100%;
            // height: 100%;
            // line-height: 110px;
            // background: rgba(0, 0, 0, 0.8);
            // top: 0;
            // color: #fff;
            // text-align: center;
            // cursor: pointer;
            // opacity: 0;
            // transition: 0.3s;
            // border-radius: 5px;
            // text-align: center;
            // display: block;
            // font-size: 20px;
        }
    }

    .img_list:hover p {
        opacity: 1;
    }
}

.itemIms2 {
    width: auto;
    display: flex;

    .img_list {
        width: 110px;
        height: 110px;
        margin-right: 10px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }

        p {
            position: absolute;
            width: 100%;
            height: 100%;
            line-height: 110px;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            color: #fff;
            text-align: center;
            cursor: pointer;
            opacity: 0;
            transition: 0.3s;
            border-radius: 5px;
            text-align: center;
            display: block;
            font-size: 20px;
        }
    }

    .img_list:hover p {
        opacity: 1;
    }
}
</style>