<template>
  <div>
    <div class="breadcrumb1">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">Individual center</el-breadcrumb-item>
        <el-breadcrumb-item>Change password</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="Change password" name="o1"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="password">
      <div class="list">
        <p>Old password</p>
        <el-input v-model="o_password" placeholder="Please enter the old password" />
      </div>

      <div class="list">
        <p>New password</p>
        <el-input v-model="password" placeholder="Please enter your new password" />
      </div>

      <div class="list">
        <p>Confirm password</p>
        <el-input v-model="y_password" placeholder="Please enter your new password" />
      </div>
    
      <div class="list">
        <el-button @click="reset" type="primary">Immediate modification</el-button>
      </div>
    </div>
  </div>
</template>
            
<script>
import { resetPassword } from "@/api/user";
export default {
  data() {
    return {
      table: [],
      activeName: "o1",
      code: "",
      o_password: "",
      y_password: "",
      password: ""
    };
  },
  methods: {
    async reset() {
      if(this.password != this.y_password) {
        return this.$message.error('两次密码输入不正确')
      }

      const res = await resetPassword({
        o_password: this.o_password,
        password: this.password
      });
      if(res.code == 200) {
        this.$message.success(res.msg)
        this.$store.commit('OUTLOGIN')
        this.$router.push('/')
      }
    },
  },
};
</script>
            
          
<style lang="less" scoped>
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
@import url("./css/info.less");
</style>
          