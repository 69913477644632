import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'
import Login from '../views/login/index.vue'
import reg from '../views/reg/index.vue'
import news from '../views/news/index.vue'
import newsDetails from '../views/news/details.vue'
import product from "../views/product/index.vue"
import productDetails from "../views/product/details.vue"
import user from "../views/user/index.vue"
import info from "../views/user/info.vue"
import order from "../views/user/order.vue"
import account from "../views/user/account.vue"
import list from "../views/home/list.vue"
import search from "../views/search/index.vue"

import about from "../views/about/index.vue"
import abouts from "../views/about/about.vue"
import fq from "../views/about/fq.vue"
import contact from "../views/about/contact.vue"


Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: home,
        redirect: '/home',
        children: [{
            path: '/home',
            name: '/home',
            component: resolve => require(['../views/home/index.vue'], resolve),
            meta: { requireAuth: true },
        }]
    },
    {
        path: '/news',
        name: 'news',
        component: news,
    },
    {
        path: '/list',
        name: 'list',
        component: list,
    },
    {
        path: '/newsDetails',
        name: 'newsDetails',
        component: newsDetails
    },
    {
        path: '/product',
        name: 'product',
        component: product
    },
    {
        path: '/search',
        name: 'search',
        component: search
    },
    {
        path: '/productDetails',
        name: 'productDetails',
        component: productDetails
    },
    {
        path: '/Login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/reg',
        name: 'reg',
        component: reg,
    },
    {
        path: '/user',
        name: 'user',
        component: user,
        children: [{
                path: '/user/info',
                component: info,
                name: '/user-info',
                meta: { requiresAuth: true }
            },
            {
                path: '/user/order',
                component: order,
                name: '/user-order'
            },
            {
                path: '/user/account',
                component: account,
                name: '/user-account',
            },
        ]
    },
    {
        path: '/about',
        name: 'about',
        component: about,
        children: [{
                path: '/about/index',
                component: abouts,
                name: '/about-index',
                meta: { requiresAuth: true }
            },
            {
                path: '/about/fq',
                component: fq,
                name: '/about-fq'
            },
            {
                path: '/about/contact',
                component: contact,
                name: '/about-contact',
            },
        ]
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router