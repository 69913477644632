<template>
  <div class="page-background">
    <v-header />
    <div class="user">
      <div class="left">
        <h3>About</h3>
        <p :class="typeIndex == 1 ? 'xz' : ''" @click="jump('/about/index',1)">About us</p>
        <p :class="typeIndex == 2 ? 'xz' : ''" @click="jump('/about/fq',2)">FAQs</p>
        <p :class="typeIndex == 3 ? 'xz' : ''" @click="jump('/about/contact',3)">Contact</p>
      </div>
      <div class="right">
        <router-view></router-view>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      typeIndex: 2
    };
  },
  methods: {
    jump(url,index) {
      this.typeIndex = index
      this.$router.push(url);
    },
  },
};
</script>
    
  
<style scoped>
</style>
  