<template>
    <div>
        <div id="header" style="padding-bottom:20px">
            <div class="tops">
                <p @click="$router.push('/about/fq')">FAQs</p>
                <p @click="$router.push('/about/index')">About us</p>
                <p @click="$router.push('/about/contact')">Contact</p>
                <p v-if="!info.email"><i class="iconfont icon-denglu"></i> My account</p>
                <p v-else @click="jump('/user/order')">
                    <i class="iconfont icon-denglu"></i> {{ info.email }}
                </p>
            </div>
            <div class="header">
                <div class="left">
                    <div class="logo" @click="jump('/')">
                        <img src="../assets/logo.jpg" alt="">
                    </div>
                </div>
                <div class="right">
                    <ul>
                        <li>
                            <a :style="$route.path == '/' || $route.path == '/home' ? 'color: #1e71ce' : ''" href="/">Home</a>
                        </li>
                        <li>
                            <a :style="
                  $route.path == '/product' || $route.path == '/productDetails'
                    ? 'color: #1e71ce'
                    : ''
                " @click="jump('/product')">All Products</a>
                        </li>
                        <li>
                            <!-- <a @click='jumpCatalog()' target="_blank">Catalog </a> -->
                            <el-dropdown>
                              <span class="el-dropdown-link">
                                Catalog<i class="el-icon-arrow-down el-icon--right"></i>
                              </span>
                              <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-for="list in JSON.parse($store.state.list)" :key="list" @click.native='jumpCatalog(list)'>{{list.name}}</el-dropdown-item>
                              </el-dropdown-menu>
                            </el-dropdown>
                        </li>
                        <li>
                            <a :style="
                  $route.path == '/news' || $route.path == '/newsDetails'
                    ? 'color: #1e71ce'
                    : ''
                " @click="jump('/news')">News</a>
                        </li>
                        <p v-if="!info.email" class="btn" @click="jump('/login')">
                            Login/Register
                        </p>
                        <p v-else class="btn" @click="out">Log out</p>
                    </ul>
                </div>
            </div>
            <div class="search">
                <i class="iconfont icon-sousuo1"></i>
                <!-- <el-autocomplete v-model="name" :fetch-suggestions="querySearchAsync" placeholder="Please enter keywords to search for products" @select="handleSelect"></el-autocomplete> -->
                <input type="text" v-model="keyword" placeholder="Please search by product name or keyword" />
                <p @click="jumpList()">Search</p>
            </div>
            <div class="info" v-if="$route.path == '/' || $route.path == '/home'">
                <p>Phone: {{JSON.parse($store.state.about).phone}}</p>
                <p>Email: {{JSON.parse($store.state.about).email}}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { getUser } from '@/api/user'
import { search } from '@/api/home'
export default {
    data() {
        return {
            user: {},
            info: {},
            data: {},
            datas: {},
            name: '',
            keyword: ''
        };
    },
    created() {
        this.getUserInfo()
        this.getInfo()
        if (process.browser) {
            this.user = JSON.parse(localStorage.getItem("user"));
        }
        this.keyword = this.$route.query.keyword
    },
    methods: {
        async querySearchAsync(queryString, cb) {
            const arr = [];
            const res = await search(this.name);
            res.data.forEach(list => {
                arr.push({
                    value: list.name,
                    id: list.id
                })
            })
            cb(arr);
        },
        jumpList() {
            window.location.href = '#/search?keyword=' + this.keyword;
            window.location.reload()
        },
        handleSelect(e) {
            if (!localStorage.getItem('token')) {
                this.$router.push("/login");
            } else {
                this.$router.push("/productDetails?id=" + e);
            }
        },
        jumpCatalog(list) {
            if (!localStorage.getItem('token')) {
                this.$router.push("/login");
            } else {
                window.open(list.url)
            }
        },
        jump(url) {
            this.$router.push(url);
        },
        out() {
            this.$store.commit("OUTLOGIN");
            window.location.reload();
            this.$router.push('/')
        },
        async getUserInfo() {
            const res = await getUser();
            this.info = res.data;
            this.$store.commit("GETUSERDATA", JSON.stringify(res.data));
        },
    },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/header.css");
@import url("../assets/css/iconfont.css");

/deep/ .el-input {
    width: 1350px !important;
    height: 55px !important;
}

/deep/ .el-dropdown-link {
    text-decoration: none;
    font-size: 18px;
    color: #000;
    font-weight: 700;
    cursor: pointer;
}

.tops p {
    cursor: pointer;
}

a {
    cursor: pointer;
}
</style>