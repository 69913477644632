<template>
    <div class="page-background" style="background: #fff">
        <div class="nav" @click="jump('/')">
            <img src="../../assets/logo.jpg" width="200px" />
        </div>
        <div class="content">
            <div class="left">
                <h3>Log in to trade account</h3>
                <div class="list">
                    <p>E-mail<span>*</span></p>
                    <el-input v-model="con.email"></el-input>
                </div>
                <div class="list">
                    <p>Password<span>*</span></p>
                    <el-input type="password" v-model="con.password"></el-input>
                </div>
                <a href="">Forgot password?</a>
                <div class="list">
                    <el-button @click="login" type="primary">Log in</el-button>
                </div>
            </div>
            <div class="right">
                <h3>Apply for an account</h3>
                <p class="con">
                    Apply for a trade only account and enjoy the following benefits:
                </p>
                <div class="list">
                    <img src="https://www.glenwayproducts.co.uk/static/version1716979048/frontend/TrophiesPlusMedals/glenway/en_GB/images/svg/green-tick.svg" alt="" />
                    <p>New product updates</p>
                </div>
                <div class="list">
                    <img src="https://www.glenwayproducts.co.uk/static/version1716979048/frontend/TrophiesPlusMedals/glenway/en_GB/images/svg/green-tick.svg" alt="" />
                    <p>Unique items for your market</p>
                </div>
                <div class="list">
                    <img src="https://www.glenwayproducts.co.uk/static/version1716979048/frontend/TrophiesPlusMedals/glenway/en_GB/images/svg/green-tick.svg" alt="" />
                    <p>Wholesaler discount</p>
                </div>
                <div class="list">
                    <img src="https://www.glenwayproducts.co.uk/static/version1716979048/frontend/TrophiesPlusMedals/glenway/en_GB/images/svg/green-tick.svg" alt="" />
                    <p>Interact with product designers</p>
                </div>
                <div class="list">
                    <img src="https://www.glenwayproducts.co.uk/static/version1716979048/frontend/TrophiesPlusMedals/glenway/en_GB/images/svg/green-tick.svg" alt="" />
                    <p>mprove profits</p>
                </div>
                <div style="height: 27px"></div>
                <div class="list" style="position: relative;top: 35px;">
                    <el-button @click="jump('/reg')" type="">Apply</el-button>
                </div>
            </div>
        </div>
        <div class="item">
            <h3>RELATED PRODUCTS</h3>
            <div class="datas">
                <div class="list" v-for="list in productList" :key="list" @click="jumpList(list)">
                    <img :src="list.imgList[0]">
                </div>
            </div>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { login, getUser } from "@/api/user";
import { getHome } from "@/api/home";
export default {
    data() {
        return {
            con: {
                email: "",
                password: "",
            },
            productList: []
        };
    },
    created() {
        this.getData()
    },
    methods: {
        jump(url) {
            this.$router.push(url)
        },
        jumpList(list) {
            if (!localStorage.getItem('token')) {
                this.$router.push("/login");
            } else {
                this.$router.push("/productDetails?id=" + list.id);
            }
        },
        async login() {
            const res = await login(this.con);
            if (res.code == 200) {
                this.$message.success('login success')
                this.getUser(res.data)
                this.$store.commit('LOGIN', res.data)
                this.$router.push('/')
            }
        },
        async getData() {
            const res = await getHome();
            this.productList = res.data.list.splice(0,4);
        },
        async getUser(data) {
            const res = await getUser(data)
            this.$store.commit('GETUSERDATA', JSON.stringify(res.data))
        }
    },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
</style>