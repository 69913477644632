<template>
    <div class="page-background" style="background: #fff">
        <div class="nav" @click="jump('/')">
            <img src="../../assets/logo.jpg" width="200px" />
        </div>
        <div class="content">
            <h3>Create Trade Account</h3>
            <p class="cons">We only accept trade account applications and full access will only be granted after information verification. Your company name, address,contact person and contact info will be used to create invoice for any future purchase and therefore correct information is essential. Thank you for your understanding.</p>
            <div class="item">
                <h2>Company</h2>
                <div class="list">
                    <p>Company name <span>*</span></p>
                    <el-input v-model="con.company_name"></el-input>
                </div>
                <div class="list">
                    <p>Company registration number<span>*</span></p>
                    <el-input v-model="con.company_reg_number"></el-input>
                </div>
                <div class="list">
                    <p>Company registration address <span>*</span></p>
                    <el-input v-model="con.company_address"></el-input>
                </div>
                <div class="list">
                    <p>Delivery address</p>
                    <span style="margin-top: -7px;margin-bottom: 20px;display: block;">Complete this if your delivery address is not your company registration address</span>
                    <el-input v-model="con.delivery_address"></el-input>
                </div>
                <div class="list">
                    <p>Post code<span>*</span></p>
                    <el-input v-model="con.postal_code"></el-input>
                </div>
                <div class="list">
                    <p>Country<span>*</span></p>
                    <el-select v-model="con.country" placeholder="Please select country">
                        <el-option v-for="item in addressList" :key="item.country_name_en" :label="item.country_name_en" :value="item.country_name_en">
                        </el-option>
                    </el-select>
                </div>
                <div class="list">
                    <p>Website Url<span>*</span></p>
                    <el-input v-model="con.web_url"></el-input>
                </div>
            </div>
            <div class="item">
                <h2>Personal</h2>
                <div class="list">
                    <p>Contact person name<span>*</span></p>
                    <el-input v-model="con.contact_person_name"></el-input>
                </div>
                <div class="list">
                    <p>Job Title</p>
                    <el-input v-model="con.job_title"></el-input>
                </div>
                <div class="list">
                    <p>Phone number with country code<span>*</span></p>
                    <el-input v-model="con.phone_code"></el-input>
                </div>
            </div>
            <div class="item">
                <h2>Company Address</h2>
                <div class="list">
                    <p>Contact person E-mail address<span>*</span></p>
                    <span style="margin-top: -7px;margin-bottom: 20px;display: block;">This email address will be used to log in the system and to reset your password</span>
                    <el-input v-model="con.email"></el-input>
                    <!-- <div class="codes">
            <el-input v-model="con.postal_code"></el-input>
            <el-button type="primary">Find Address</el-button>
          </div> -->
                </div>
                <!-- <span></span> -->
                <div class="list">
                    <p>Password<span>*</span></p>
                    <span style="margin-top: -7px;margin-bottom: 20px;display: block;">Password for log in</span>
                    <el-input v-model="con.password"></el-input>
                    <!-- <div class="codes">
            <el-input v-model="con.postal_code"></el-input>
            <el-button type="primary">Find Address</el-button>
          </div> -->
                </div>
                <div class="list">
                    <p>Re-type your password<span>*</span></p>
                    <el-input v-model="con.yes_password"></el-input>
                </div>
                <div class="list">
                    <el-button @click="reg" type="primary">Apply for a trade account</el-button>
                </div>
            </div>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { register } from "@/api/user"
export default {
    data() {
        return {
            con: {}
        };
    },
    created() {
        this.addressList = this.$setting.ADDRESSLIST
    },
    methods: {
        async reg() {
            const res = await register(this.con);
            if (res.code == 200) {
                this.$message.success(res.msg)
            }
        },
    },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
</style>