<template>
    <div class="page-background-w">
        <v-header></v-header>
        <div class="data">
            <div class="item">
                <div class="list" v-for="(list, index) in productList" :key="index" @click="jump(list)">
                    <div class="imgs">
                        <el-carousel height="200px" :autoplay="false" :initial-index="list.index">
                            <el-carousel-item v-for="(v,key) in list.imgList" :key="key">
                                <img :src="v" alt="" />
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="box">
                        <p class="type">{{ list.name }}</p>
                        <p class="name">{{ list.con }}</p>
                        <p class="price" v-if="list.info">
                            ${{ list.info.price ? list.info.price : 0 }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination">
            <el-pagination background @current-change="pageChange" :page-size="pageSize" layout="prev, pager, next" :total="total">
            </el-pagination>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { search } from "@/api/home";
export default {
    data() {
        return {
            pageNum: 1,
            pageSize: 20,
            productList: [],
            total: 0
        };
    },
    created() {
        this.getProduct()
    },
    methods: {
        pageChange(e) {
            this.pageNum = e;
            this.getProduct();
        },
        // this.$route.query.keyword
        async getProduct() {
            const res = await search(this.$route.query.keyword, this.pageNum, this.pageSize);
            this.productList = res.data.list;
            this.total = res.data.total
        },
        jump(list) {
            if (!localStorage.getItem('token')) {
                this.$router.push("/login");
            } else {
                this.$router.push("/productDetails?id=" + list.id);
            }
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

/deep/ .is-active .el-carousel__button {
    background: #fc4c02;
}

/deep/ .el-carousel__indicators--horizontal {
    bottom: -7px;
}


.item {
    width: 1350px;
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;

    /deep/ .el-carousel {
        overflow: hidden !important;
    }

    .list {
        width: 24%;
        height: auto;
        display: flex;
        flex-direction: column;
        /* background-color: #fff; */
        /* background-color: #f5f6f7; */
        padding-top: 20px;
        padding-bottom: 20px;
        margin: 0% 0% 0% 1%;
        /* padding-bottom: 20px; */
        box-shadow: 0 0 9px 0 rgba(166, 166, 166, 0.23);
        /* float: left; */
        margin-top: 8px;
        margin-bottom: 5px;
        border-radius: 10px;
        cursor: pointer;

        .imgs {
            width: 90%;
            height: 200px;
            margin: auto;
            border-bottom: 1px solid rgba(225, 225, 225, 0.7);

            img {
                height: 100%;
                margin: 3px auto;
                display: block;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }

        .box {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: auto;

            .type {
                width: 100%;
                height: 50px;
                /* margin: auto; */
                font-size: 18px;
                font-weight: 600;
                padding-top: 10px;
                margin-bottom: 10px;
                color: #000;
                opacity: 0.8;
            }

            .name {
                width: 100%;
                /* margin: auto; */
                font-weight: 400;
                font-size: 16px;
            }

            .price {
                width: 100%;
                /* margin: auto; */
                color: #000;
                font-weight: 600;
                padding-top: 20px;
                font-size: 18px;
            }
        }
    }
}
</style>