<template>
    <div>
        <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">About</el-breadcrumb-item>
                <el-breadcrumb-item>About us</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="data">
            <div v-html="qa"></div>
        </div>
    </div>
</template>
<script>
import { getAbout } from '@/api/home'
export default {
    data() {
        return {
            table: [],
            activeName: "o1",
            qa: ''
        };
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            const res = await getAbout();
            this.qa = res.data.qa
        },
    }
};
</script>
<style scoped>
</style>