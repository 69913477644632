<template>
    <div class="page-background" style="background: #fff">
        <v-header></v-header>
        <div class="breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/#/product">All Products</a></el-breadcrumb-item>
                <el-breadcrumb-item><a :href="'/#/product?type=' + info.fname + '&typeName=' + info.fname ">{{ info.fname }}</a></el-breadcrumb-item>
                 <el-breadcrumb-item><a :href="'/#/product?id=' + info.type + '&typeName=' + info.type_name ">{{ info.type_name }}</a></el-breadcrumb-item>
                <el-breadcrumb-item>{{ info.name }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="details">
            <div class="ds">
                <div class="ds-left">
                    <div class="data">
                        <img :src="img" />
                    </div>
                    <div class="imgs">
                        <img v-for="(list, index) in info.imgList" :key="index" :src="list" :class="selectIndex == index ? 'xz' : ''" @click="
                img = list;
                selectIndex = index;
              " />
                    </div>
                </div>
                <div class="ds-right">
                    <div class="title">{{ info.name }}<br><p style="font-size:22px;">{{ info.con }}</p></div>
                    <div class="description">
                        <h3>Product Introduction：</h3>
                        <p>{{ info.details }}</p>
                    </div>
                    <!-- <div class="btn" @click="save">
            <p class="pay">Submit an order</p>
          </div> -->
                </div>
            </div>
            <div class="items">
                <div class="nav">
                    <p style="width:10%;text-align: center;">Photo</p>
                    <p style="width:19%">Item No.</p>
                    <p style="width:15%">Colour</p>
                    <p style="width:15%">Dimension(mm)</p>
                    <p style="width:10%">T(mm)</p>
                    <p style="width:10%"># per Box</p>
                    <p style="width:10%">Price</p>
                    <p style="width:10%">Quantity</p>
                    <p style="width:10%;text-align: center;">Order</p>
                </div>
                <div class="list" v-for="(list, index) in info.skuList" :key="index">
                   <div class="imgs" style="width:10%">
                        <img :src="list.img" :alt="list.name" />
                    </div>

                    <div style="width: 19%">
                        <p>{{list.sku}}</p>
                    </div>
                    <div style="width: 15%">
                        <p>{{list.color}}</p>
                    </div>
                    <p style="width: 15%">{{list.dimension}}</p>
                    <p style="width: 10%">{{list.t}}</p>
                    <p style="width: 10%">{{list.perBox}}</p>

                    <p style="width: 10%">${{list.price}}</p>
                    <p style="width: 10%;">
                        <el-input-number v-model="list.number" @change="handleChange" :min="1" size="mini" label="描述文字"></el-input-number>
                    </p>
                    <p style="width:10%;display: flex;justify-content: flex-end;align-items: center;" @click="save(list)">
                        <el-button type="primary" size="mini">Order Now</el-button>
                    </p>
                </div>
            </div>

            <div class="related">
                <h3>RELATED PRODUCTS</h3>
                <!-- <div class="datas like">
                <ul>
                    <li>
                        <a href="#">
                            <div class="imgs">
                                <img src="https://www.trendsettingawards.com/product_images/MB1777.jpg" alt="">
                            </div>
                            <div class="title">Trailblazer Football Heavyweight Male</div>
                            <div class="price">
                                ¥14.50
                                <del>¥30.30</del>
                            </div>
                        </a>
                    </li>
                </ul>
            </div> -->
            </div>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { details } from "@/api/product";
import { saveOrder } from "@/api/user";
export default {
    data() {
        return {
            info: {},
            size: [],
            color: [],
            img: "",
            selectIndex: 0,
            sizeIndex: 0,
            colorIndex: 0,
            number: 1,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async save(list) {
            list.id = this.info.id
            const res = await saveOrder({
                size: list.size,
                dimension: list.dimension,
                t: list.t,
                sku: list.sku,
                perBox: list.perBox,
                color: list.color,
                number: list.number,
                img: list.img ? list.img : "",
                price: Number(list.price * list.number),
                name: this.info.name,
                datas: list
            });
            if (res.code == 200) {
                this.$message.success(res.msg);
            }
        },
        async getData() {
            const res = await details(this.$route.query.id);
            if (res.data.skuList) {
                res.data.skuList.forEach((list) => {
                    // this.size.push(list.size);
                    // this.color.push(list.color);
                    list.number = 1;
                });
            } else {
                res.data.skuList = [];
            }
            console.log(res.data.fid)
            this.info = res.data;
            this.img = res.data.imgList[0];
        },
        getBanner() {
            setInterval(() => {
                this.selectIndex++;
                if (this.selectIndex == this.info.imgList.length) {
                    this.selectIndex = 0;
                }
                this.img = this.info.imgList[this.selectIndex];
            }, 3000);
        },
        reduction() {
            if (this.number != 0) {
                this.number--;
            }
        },
        add() {
            this.number++;
        },
    },
};
</script>
<style lang="less" scoped>
/deep/ .el-input-number--mini {
    width: 120px !important;
    text-indent: 0px;
}

.items .nav p {
    text-indent: 0px !important;
}

@import url("./details.less");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
</style>