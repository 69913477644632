<template>
    <div class="page-background">
        <v-header></v-header>
        <div class="banner">
            <el-carousel height="600px">
                <el-carousel-item v-for="item in bannerList" :key="item">
                    <img :src="item.img" :alt="item.title" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="sort product">
            <div class="select">
                <p>TOP PRODUCT CATAGORIES</p>
            </div>
            <div class="datas">
                <div class="item2">
                    <div class="list2" v-for="list in typeList" :key="list" @click="jumpType()">
                        <div class="imgs2">
                            <img :src="list.img" :alt="list.name" />
                        </div>
                        <div class="lbox">
                            <h3 class="title">{{ list.name }}</h3>
                            <p class="con">{{ list.hair_time }}</p>
                            <p class="price">{{list.price_section}}</p>
                            <span class="view">View</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sort product">
            <!-- <h3>New Products <span>view More</span></h3> -->
            <div class="select">
                <p>POPULAR PRODUCTS</p>
            </div>
            <div class="datas">
                <div class="item">
                    <div class="list" v-for="(list,index) in productList" :key="index" @click="jump(list)" @mouseenter="handleMouseEnter(index)">
                        <div class="imgs">
                            <!-- <img :src="list.imgList[0]" alt="" /> -->
                            <el-carousel height="200px" :autoplay="false" :initial-index="list.index">
                                <el-carousel-item v-for="(v,key) in list.imgList" :key="key">
                                    <img :src="v" alt="" />
                                </el-carousel-item>
                                <!-- <el-carousel-item>
                                    <img :src="list.imgList[1]" alt="" />
                                </el-carousel-item> -->
                            </el-carousel>
                        </div>
                        <div class="box">
                            <p class="type">{{ list.name }}</p>
                            <p class="name">{{ list.con }}</p>
                            <p class="price" v-if="list.info">${{ list.info.price ? list.info.price : 0 }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { getHome,getAbout } from "@/api/home";
export default {
    data() {
        return {
            productList: [],
            bannerList: [],
            typeList: [],
            initialIndex: 0,
            info: {}
        };
    },
    created() {
        this.getData();
        this.getInfo()
    },
    methods: {
        jump(list) {
            if (!localStorage.getItem('token')) {
                this.$router.push("/login");
            } else {
                this.$router.push("/productDetails?id=" + list.id);
            }
        },
        jumpType() {
            if (!localStorage.getItem('token')) {
                this.$router.push("/login");
            } else {
                this.$router.push("/product");
            }
        },
        handleMouseEnter(index) {
            this.productList[index]['index'] = 1
            this.$forceUpdate()
        },
        async getData() {
            const res = await getHome();
            this.productList = res.data.list;
            res.data.list.forEach(list => {
                list['index'] = 0
            })
            this.typeList = res.data.type;
            this.bannerList = res.data.banner;
        },
        async getInfo() {
            const res = await getAbout();
            this.info = res.data;
        },
    },
};
</script>
<style scoped>
/deep/ .el-carousel__button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
}

/deep/ .is-active .el-carousel__button {
    background: #fc4c02;
}

/deep/ .el-carousel__indicators--horizontal {
    bottom: -7px;
}

@import url("./index.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
</style>