<template>
    <div class="page-background">
    </div>
</template>
<script>
export default {
    data() {
        return {
        };
    },
    created() {
    },
    methods: {
    },
};
</script>
<style lang="less" scoped>
</style>