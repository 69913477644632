<template>
    <div class="page-background" style="background: #fff;">
        <v-header></v-header>
        <div class="breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/news">News</a></el-breadcrumb-item>
                <el-breadcrumb-item>List</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="news">
            <div class="list" v-for="list in dataList" :key="list" @click="jump(list)">
                <div class="imgs">
                    <img src="https://img1.baidu.com/it/u=2871911469,4089955866&fm=253&fmt=auto&app=138&f=JPEG?w=428&h=285" alt="" />
                </div>
                <div class="box">
                    <p class="title">
                        America keeps the war going: "It's the result of weak American
                        diplomacy"
                    </p>
                    <p class="con">
                        Since Israeli forces seized the Rafah crossing to Egypt at the
                        southern tip of the Gaza Strip earlier this month, the key crossing
                        has been closed, preventing aid from entering Gaza in a timely
                        manner.
                    </p>
                </div>
            </div>
        </div>
        <div class="pagination">
            <el-pagination background layout="prev, pager, next" :total="total">
            </el-pagination>
        </div>
        <v-footer></v-footer>
    </div>
</template>
<script>
import { list } from '@/api/news'
export default {
    data() {
        return {
            dataList: [],
            pageNum: 1,
            pageSize: 10,
            total: 0
        };
    },
    created() {
        this.getList()
    },
    methods: {
        jump(list) {
            this.$router.push('/newsDetails?id=' + list.id)
        },
        async getList() {
            const res = await list(this.pageNum, this.pageSize, '')
            this.dataList = res.data.list
            this.total = res.data.total
        }
    }
};
</script>
<style lang="less" scoped>
@import url("./index.less");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
</style>