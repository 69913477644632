<template>
    <div>
        <div class="breadcrumb1">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">Individual center</el-breadcrumb-item>
                <el-breadcrumb-item>My order</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="tabs">
            <el-button type="primary" @click="dialogVisible = true">Print Order</el-button>
        </div>
        <div class="bottom order">
            <div class="table">
                <div class="tbottom">
                    <el-table :data="table" max-height="300" header-cell-style="background: #f5f7fa;">
                        <!-- <el-table-column align="center" prop="date" label="ID">
              <template slot-scope="scope">{{ scope.$index + 1 }}</template>
            </el-table-column> -->
                        <el-table-column align="center" prop="oid" label="orderId" width="220" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" width="180" prop="sku" label="Item No." :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" prop="color" label="Colour" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" prop="name" label="Photo" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <img :src="scope.row.img" width="30" height="30" alt="">
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="dimension" width="150" label="Dimension(mm)" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" prop="t" width="150" label="T(mm)" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" prop="perBox" width="150" label="# per Box " :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" prop="number" label="Quantity" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column align="center" prop="price" width="200" label="Transaction amount"></el-table-column>
                        <el-table-column width="200" align="center" prop="createtime" label="Time" :show-overflow-tooltip="true"></el-table-column>
                        <!-- <el-table-column
              align="center"
              prop="createtime"
              label="operation"
            >
            <template slot-scope="scope">
              <el-link v-if="scope.row.state == 1" @click.stop="delOrder(scope.row)" type="danger">删除订单</el-link>
              <el-link v-if="scope.row.state == 0" @click.stop="pay(scope.row)" type="primary">立即支付</el-link>
            </template>
            </el-table-column> -->
                    </el-table>
                </div>
            </div>
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>
        <operationOrder :dialogVisible="dialogVisible" :dataList="table" />
    </div>
</template>
<script>
import { geUserOrder } from '@/api/user'
import operationOrder from './components/operationOrder.vue';
export default {
    data() {
        return {
            table: [],
            activeName: '-1',
            pageNum: 1,
            pageSize: 10,
            total: 0,
            dialogVisible: false
        };
    },
    components: {
        operationOrder
    },
    created() {
        this.getList()
    },
    methods: {
        async getList() {
            const res = await geUserOrder(this.pageNum, this.pageSize);
            this.table = res.data.list
            this.total = res.data.total
            console.log(res)
        }
    }
};
</script>
<style lang="less" scoped>
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
@import url("./css/info.less");

.tabs {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: flex-end;

    .el-button {
        margin-right: 30px;
        background: #1e71ce !important;
        border-color: #1e71ce !important;
    }
}
</style>