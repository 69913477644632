import request from '../utils/request'

// 用户注册
export const register = (data) => request.post('/user/register',data);

// 用户登录
export const login = (data) => request.post('/user/login',data);

// 获取用户信息
export const getUser = (data) => request.get('/user/getInfo', data);

// 修改密码
export const resetPassword = (data) => request.post('/user/resetPassword', data);

// 提交订单
export const saveOrder = (data) => request.post('/user/saveOrder',data);

// 用户订单
export const geUserOrder = (pageNum,pageSize) => request.get('/user/getOrder?pageNum=' + pageNum + '&pageSize=' + pageSize);