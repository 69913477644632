import Vue from 'vue'
import Vuex from 'vuex'
// upInformation

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
    about: localStorage.getItem('about') ? JSON.parse(localStorage.getItem('about')) : {},
    list: localStorage.getItem('list') ? JSON.parse(localStorage.getItem('list')) : [],
  },
  mutations: {
    LOGIN(state, data) {
      state.token = data
      localStorage.setItem('token', data)
    },
    ABOUT(state,data) {
      state.about = data
      localStorage.setItem('about', data)
    },
    URLLIST(state,data) {
      state.list = data
      localStorage.setItem('list', data)
    },
    OUTLOGIN(state) {
      state.token = ''
      state.use = {}
      localStorage.clear()
    },
    GETUSERDATA(state, data) {
      state.user = data
      localStorage.setItem('user', data)
    }
  }
})
