<template>
  <div class="bg">
    <v-header></v-header>
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item><a href="/news">News</a></el-breadcrumb-item>
        <el-breadcrumb-item>{{info.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="ninfo">
      <div class="left">
        <div class="time">{{info.title}}</div>
        <div class="title">{{info.time}}&nbsp;&nbsp;&nbsp;&nbsp;Number of views：1</div>
        <div class="details" v-html="info.intro"></div>
      </div>
      <div class="right">
        <p class="title">Recommend for you</p>
        <ul>
          <li
            v-for="list in newsList"
            :key="list"
            @click="jump(list)"
          >
            {{ list.title }}
          </li>
        </ul>
      </div>
    </div>

    <v-footer></v-footer>
  </div>
</template>
    
<script>
import { list,details } from "@/api/news";
export default {
  data() {
    return {
      info: {},
      newsList: [],
    };
  },
  created() {
   this.getData()
   this.getList()
  },
  methods: {
    jump(list) {
      this.$router.push('/newsDetails?id=' + list.id)
    },
    async getData() {
      const res = await details(this.$route.query.id)
      this.info = res.data
    },
    async getList() {
      const res = await list(1,10,1)
      this.newsList = res.data.list
    }
  },
};
</script>
        
<style lang="less" scoped>
@import url("./index.less");
@import url("../../assets/css/style.css");
@import url("../../assets/css/iconfont.css");
.bg {
  min-height: 100vh;
  background: #f5f6f7;
}
</style>
  