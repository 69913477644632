<template>
  <div>
    <div class="footer">
        <div class="f-box">
            <div class="left">
                <p>{{JSON.parse($store.state.about).title}}</p>
                <span>{{JSON.parse($store.state.about).info}}</span>
                <span>Phone: {{JSON.parse($store.state.about).phone}}</span>
                <span>email: {{JSON.parse($store.state.about).email}}</span>
                <!-- <span>Industrial Control Sales Consultant: xxx</span> -->
            </div>
            <div class="right" style="flex-direction: row;">
                <!-- <p>Contact</p> -->
                <div class="">
                    <img style="padding: 5px;background: #ffff;border-radius: 5px;" src="../assets/logos.jpeg" />
                    <p style="font-size:16px;text-align: center;">Whatsapp</p>
                </div>
                 <!-- <div class="">
                    <img src="/static/image/code2.jpg" style="background: #fff;padding: 8px;width: 138px;margin-left: 12px;" alt="{$setting.name}">
                    <p style="font-size:16px;text-align: center;">Wechat</p>
                </div> -->
            </div>
        </div>
    </div>
    <div class="bottom1">
        <p>{{JSON.parse($store.state.about).bottom}}</p>
    </div>
  </div>
</template>
  
<script>
export default {
    data() {
        return {
            info: {}
        };
    },
    // watch: {
    //     '$store.state.about': {
    //         handler(val) {
    //             this.info = JSON.parse(val)
    //         }
    //     },
    //     'deep': true
    // },
};
</script>
  

<style scoped>
@import url("../assets/css/footer.css");
</style>