import axios from "axios";// 创建一个axios实例
import router from '../router'
import { Message } from "element-ui";
const service = axios.create({
    baseURL: '/api', // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout 10s
});

// 请求拦截器
service.interceptors.request.use(config => {

    config.headers['token'] =  localStorage.getItem('token') ? localStorage.getItem('token') : '';
    // do something before request is sent
    return config;
},error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(response => {
    if(response.data.code == 500) {
        return Message.error(response.data.msg);
    }

    if(response.data.code == 501) {
        router.push('/login')
    }

    return response.data
},
error => {
    console.log('err' + error); // for debugreturn Promise.reject(error);
})

export default service;